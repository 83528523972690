import React, { useEffect, useState } from 'react'
import Header from './header';
import Footer from './footer';
import BackToTop from './backtotop';
import { CurvyDividerBottom } from './dividers';

const MainBanner = "/img/Image.webp"

function Layout({children}) {

    const [offset, setOffset] = useState(0);
  
    useEffect(() => {
      const handleScroll = () => setOffset(window.pageYOffset);
      window.addEventListener('scroll', handleScroll);
  
      return () => window.removeEventListener('scroll', handleScroll);
    }, []);

  return (
    <>
        <div className="relative bg-fixed bg-bottom bg-cover min-h-[500px] lg:min-h-[700px] max-w-[2560px] mx-auto" 
            style={{ 
            backgroundImage: `url(${MainBanner})`,
            backgroundPositionY: offset * -0.3,
            }}>
            <div className="absolute w-full h-full bg-white opacity-50 lg:hidden block"></div>
            <Header />
            <CurvyDividerBottom />
        </div>
        <main className="flex flex-col gap-20 lg:gap-32 pb-20 bg-gradient-to-t from-slate-100 via-white to-slate-100 max-w-[2560px] w-full mx-auto">
            {children}
        </main>
        <BackToTop />
        <Footer />
    </>
  )
}

export default Layout
