import React from 'react'
import NavMenu from './navbar'
import CountdownTimer from './countdown'

function Header() {
  return (
    <header>
        <div className="max-w-screen-xl mx-auto py-5 flex justify-between items-center lg:items-end flex-col lg:flex-row gap-5 relative z-10">
            <a href="/">
                <img
                    src="/img/ASBANSW-logo.png"
                    width={300}
                    alt="ASBANSW Logo"
                />
            </a>
            <div className="flex flex-col items-end gap-5 border-t border-black lg:border-0">
                <NavMenu className="fixed top-5 end-5 lg:hidden" />
                <CountdownTimer targetDate="2024-10-08" />
                <img
                    src="/img/ASBANSW-slogan.png"
                    width={300}
                    alt="ASBANSW Logo"
                />
            </div>
        </div>
        <div className="backdrop-blur-md bg-white/50 py-3 lg:flex justify-center mb-10 hidden">
            <NavMenu />
        </div>
    </header>
  )
}

export default Header
