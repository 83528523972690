import React, { useEffect, useState } from 'react'
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
  navigationMenuTriggerStyle,
} from "@/components/ui/navigation-menu"
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTrigger,
} from "@/components/ui/sheet"
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion"
import { Menu } from 'lucide-react'

  const registration = [
    {
        title: "Registration",
        href: "/registration",
        description:
            "Register for the ASBANSW 2024.",
    },
    {
        title: "Accommodation",
        href: "/accommodation",
        description:
            "Check the availability of accomodation for the ASBANSW 2024.",
    },
    {
        title: "Terms and Conditions",
        href: "/terms-and-conditions",
        description:
            "Read the terms and conditions for the ASBANSW 2024.",
    },
  ];

  const sponsorship = [
    {
        title: "Sponsorship and Exhibition Opportunities",
        href: "/sponsorship-and-exhibition",
        description:
            "Find out more about the ASBANSW 2024 sponsorship and exhibition opportunities.",
    },
    {
        title: "Sponsors and Exhibitors",
        href: "/sponsors-and-exhibitors",
        description:
            "Explore the esteemed sponsors and exhibitors contributing to our event's success",
    }
  ];

  const destination = [
    {
        title: "Venue",
        href: "/venue",
        description:
            "A snapshot of our prestigious gathering, fostering collaboration and innovation.",
    },
    {
        title: "Destination",
        href: "/destination",
        description:
            "Unveil where cultural vibrancy meets natural wonder and adventure.",
    },
  ];

function NavMenu({className}) {

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth < 1023);
    }

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <nav className={className}>
        {isMobile ? <MobileMenu /> : <DesktopMenu />}
    </nav>
  )
}

export default NavMenu

function DesktopMenu() {
  return (
    <NavigationMenu>
          <NavigationMenuList>

              <NavigationMenuItem>
                <NavigationMenuLink href="/" className={navigationMenuTriggerStyle()}>
                  Home
                </NavigationMenuLink>
              </NavigationMenuItem>

              <NavigationMenuItem>
                <NavigationMenuLink href="/program" className={navigationMenuTriggerStyle()}>
                  Program
                </NavigationMenuLink>
              </NavigationMenuItem>

              <NavigationMenuItem>
              <NavigationMenuTrigger>Registration</NavigationMenuTrigger>
              <NavigationMenuContent>
                  <ul className="backdrop-blur-md bg-white/80 grid w-[400px] gap-3 p-4 md:w-[500px] md:grid-cols-2 lg:w-[600px]">
                  {registration.map((menu) => (
                      <ListItem
                      key={menu.title}
                      title={menu.title}
                      to={menu.href}
                      >
                      {menu.description}
                      </ListItem>
                  ))}
                  </ul>
              </NavigationMenuContent>
              </NavigationMenuItem>

              <NavigationMenuItem>
              <NavigationMenuTrigger>Sponsors & Exhibitors</NavigationMenuTrigger>
              <NavigationMenuContent>
                <ul className="backdrop-blur-md bg-white/80 grid w-[400px] gap-3 p-4 md:w-[500px] md:grid-cols-2 lg:w-[600px]">
                  {sponsorship.map((menu) => (
                      <ListItem
                      key={menu.title}
                      title={menu.title}
                      to={menu.href}
                      >
                      {menu.description}
                      </ListItem>
                  ))}
                </ul>
              </NavigationMenuContent>
              </NavigationMenuItem>

              <NavigationMenuItem>
              <NavigationMenuTrigger>Destination</NavigationMenuTrigger>
              <NavigationMenuContent>
                <ul className="backdrop-blur-md bg-white/80 grid w-[400px] gap-3 p-4 md:w-[500px] md:grid-cols-2 lg:w-[600px]">
                  {destination.map((menu) => (
                      <ListItem
                      key={menu.title}
                      title={menu.title}
                      to={menu.href}
                      >
                      {menu.description}
                      </ListItem>
                  ))}
                </ul>
              </NavigationMenuContent>
              </NavigationMenuItem>

              <NavigationMenuItem>
                <NavigationMenuLink href="/contact-us" className={navigationMenuTriggerStyle()}>
                  Contact Us
                </NavigationMenuLink>
              </NavigationMenuItem>

          </NavigationMenuList>
        </NavigationMenu>
  )
}

function MobileMenu() {
    return (
      <Sheet>
        <SheetTrigger><Menu width="40" height="40" className="bg-white p-2 rounded-lg" /></SheetTrigger>
        <SheetContent className="bg-white">
          <SheetHeader>
            <SheetDescription>

            <Accordion type="single" collapsible id="mobilemenu">

              <AccordionItem value="item-1">
                <a
                  href="/"
                  className="flex text-lg font-bold py-4 parentmenu text-black"
                  >
                  Home
                </a>
              </AccordionItem>

              <AccordionItem value="item-2">
                <a
                  href="/program"
                  className="flex text-lg font-bold py-4 parentmenu text-black"
                  >
                  Program
                </a>
              </AccordionItem>

              <AccordionItem value="item-3">
                <AccordionTrigger className="parentmenu text-lg hover:no-underline text-black font-bold">Registration</AccordionTrigger>
                <AccordionContent className="childmenu flex flex-col items-start text-start gap-4">
                  {registration.map((menu) => (
                    <a
                    key={menu.title}
                    href={menu.href}
                    >
                    {menu.title}
                    </a>
                  ))}
                </AccordionContent>
              </AccordionItem>

              <AccordionItem value="item-4">
                <AccordionTrigger className="parentmenu text-lg hover:no-underline text-black font-bold">Sponsors & Exhibitors</AccordionTrigger>
                <AccordionContent className="childmenu flex flex-col items-start text-start gap-4">
                  {sponsorship.map((menu) => (
                    <a
                    key={menu.title}
                    href={menu.href}
                    >
                    {menu.title}
                    </a>
                  ))}
                </AccordionContent>
              </AccordionItem>

              <AccordionItem value="item-5">
                <AccordionTrigger className="parentmenu text-lg hover:no-underline text-black font-bold">Destination</AccordionTrigger>
                <AccordionContent className="childmenu flex flex-col items-start text-start gap-4">
                  {destination.map((menu) => (
                    <a
                    key={menu.title}
                    href={menu.href}
                    >
                    {menu.title}
                    </a>
                  ))}
                </AccordionContent>
              </AccordionItem>

              <AccordionItem value="item-6">
                <a
                  href="/contact-us"
                  className="flex text-lg font-bold py-4 parentmenu text-black"
                  >
                  Contact Us
                </a>
              </AccordionItem>

            </Accordion>

            </SheetDescription>
          </SheetHeader>
        </SheetContent>
      </Sheet>
    )
}

const ListItem = React.forwardRef((props, ref) => {
    const { className, title, children, ...rest } = props;
    return (
      <li>
        <NavigationMenuLink asChild>
          <a
            href={props.to}
            ref={ref}
            className={`block select-none space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground ${className}`}
            {...rest}
          >
            <div className="text-sm font-bold leading-none">{title}</div>
            <p className="line-clamp-2 text-sm leading-snug text-muted-foreground">
              {children}
            </p>
          </a>
        </NavigationMenuLink>
      </li>
    );
  });
  ListItem.displayName = "ListItem";